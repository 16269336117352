import React from "react";
import colors from "../../config/colors";
import ShowPTBtn from "./ShowBtn";

const PaymentCard = ({ payment, polling }) => {
  const Loading = () => {
    return (
      <div>
        <div style={{ color: "white" }}>Loading...</div>
      </div>
    );
  };

  const ShowBtn = ({ thisPayment }) => {
    if (polling === "paid") {
      return (
        <div>
          <h4>Total: N${payment?.total}</h4>
          <p>Payment completed</p>
        </div>
      );
    }
    if (polling === "loading") {
      return (
        <div>
          <h4>Total: N${payment?.total}</h4>
          <p>Loading...</p>
        </div>
      );
    }
    if (polling === "error") {
      return (
        <div>
          <h4>Total: N${payment?.total}</h4>
          <p>There was an error checking your transaction status</p>
        </div>
      );
    }
    if (polling === "unpaid") {
      return (
        <div>
          <h4>Total: N${payment?.total}</h4>
          <div id="paytodaybtn" />
          <ShowPTBtn thisPayment={thisPayment} />
        </div>
      );
    }
  };

  return (
    <div style={styles.container}>
      <div style={{ color: "white" }}>
        <h2 style={{ lineHeight: 0 }}>Make Payment</h2>
        {!payment ? <Loading /> : <ShowBtn thisPayment={payment} />}
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: colors.text,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 10,
    marginTop: 64,
    marginBottom: 64,
    maxWidth: "80vw",
  },
};

export default PaymentCard;
