import React, { useState, useEffect } from "react";
import Navbar from "../../components/UI/Navbar";
import Backdrop from "../../components/UI/Backdrop";
import SideMenu from "../../components/UI/SideMenu";
import Button from "../../components/UI/Button";
import MainFooter from "../../components/UI/Footer";

import globalStyles from "../../config/globalStyles";
import { useSelector, useDispatch } from "react-redux";
import { MdHome, MdLabel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/authActions";
import colors from "../../config/colors";
import CustomerDetailsForm from "./CustomerDetailsForm";
import heroImg from "../../assets/hero.png";
import { getTickets } from "../../actions/ticketActions";

const CustomerDetailsHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleSideMenu, setToggleSideMenu] = useState(false);

  useEffect(() => {
    dispatch(getTickets());
  }, [dispatch]);

  const handleSideMenu = () => {
    setToggleSideMenu(!toggleSideMenu);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate.push(`/login`);
  };

  const auth = useSelector((state) => state.auth);
  const tickets = useSelector((state) => state.tickets);

  const Footer = () => {
    return (
      <Button
        style={styles.footerBtn}
        title="Logout"
        handlePress={handleLogout}
      />
    );
  };

  const bodyLinks = [
    {
      title: "Home",
      to: "/home",
      icon: <MdHome style={globalStyles.icon} />,
    },
  ];

  const authBodyLinks = [
    {
      title: "Tickets",
      to: "/tickets",
      icon: <MdLabel style={globalStyles.icon} />,
    },
  ];

  return (
    <div>
      <div>
        <Navbar onClick={handleSideMenu} />
        {toggleSideMenu ? <Backdrop onClick={handleSideMenu} /> : null}
        {!auth.auth?.token ? (
          <SideMenu isOpen={toggleSideMenu} bodyLinks={bodyLinks} />
        ) : null}
        {auth.auth?.token ? (
          <SideMenu
            isOpen={toggleSideMenu}
            bodyLinks={authBodyLinks}
            footer={<Footer />}
          />
        ) : null}
        <div style={styles.container}>
          <div style={{ color: colors.primary }}>
            <CustomerDetailsForm tickets={tickets} />
          </div>
        </div>
        <MainFooter />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100vw",
    backgroundImage: `url(${heroImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    minHeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footerBtn: {
    boxShadow: "none",
  },
  content: {
    marginTop: 56,
  },
};

export default CustomerDetailsHome;
