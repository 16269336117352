import React from "react";
import { FaYoutube, FaInstagram } from "react-icons/fa";
import colors from "../../config/colors";

const Footer = () => {
  return (
    <div style={styles.container}>
      <h1 style={{ marginBottom: 20, lineHeight: 0 }}>BAR-HP</h1>
      <p style={{ marginBottom: 20, textAlign: "center" }}>
        Copyright © 2023 BARHP Events CC
      </p>
      <div style={styles.iconContainer}>
        <div
          style={{
            border: `1px solid ${colors.secondary}`,
            padding: 15,
            paddingBottom: 10,
            borderRadius: 10000,
            alignItems: "center",
            justifyContent: "center",
            marginRight: 5,
          }}
        >
          <a
            href="https://www.youtube.com/watch?v=oo2iUu-K8zo"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube style={{ fontSize: 24, color: colors.secondary }} />
          </a>
        </div>
        <div
          style={{
            border: `1px solid ${colors.secondary}`,
            padding: 15,
            paddingBottom: 10,
            borderRadius: 10000,
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 5,
          }}
        >
          <a
            href="https://www.instagram.com/spitzkoppebarhp/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram style={{ fontSize: 24, color: colors.secondary }} />
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "black",
    padding: 50,
    backgroundColor: "#f1f1f1",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
  },
};

export default Footer;
