import React, { useState } from "react";
import colors from "../../config/colors";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { APIURL } from "../../config/vars";
import { useDispatch } from "react-redux";
import { purchaseTickets, setFinalTotal } from "../../actions/ticketActions";
import { useNavigate } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";

const Form = ({ tickets }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const biggerThan600 = useMediaPredicate("(min-width: 600px)");
  const [ticketsPurchased, setTicketsPurchased] = useState([
    { name: "", ticketID: "" },
  ]);
  const [errMsg, setErrMsg] = useState("");

  if (!tickets.tickets.length) {
    return;
  }

  const handleAddTicket = () => {
    const ticketsCopy = [...ticketsPurchased];
    ticketsCopy.push({ name: "", ticketID: "" });
    setTicketsPurchased(ticketsCopy);
  };

  const handleRemoveTicket = (index) => {
    const ticketsCopy = [...ticketsPurchased];
    let updateTickets = [];

    for (let i = 0; i < ticketsCopy.length; i++) {
      if (i !== index) {
        updateTickets.push(ticketsCopy[i]);
      }

      if (ticketsCopy.length === i + 1) {
        setTicketsPurchased(updateTickets);
      }
    }
  };

  const handleNameChange = (value, index) => {
    let ticketsCopy = [...ticketsPurchased];
    let updateTickets = [];
    ticketsCopy.forEach((element, i) => {
      if (i !== index) {
        updateTickets.push(element);
      } else {
        updateTickets.push({
          name: value,
          ticketID: element.ticketID,
        });
      }

      if (ticketsCopy.length === i + 1) {
        setTicketsPurchased(updateTickets);
      }
    });
  };

  const handleTicketChange = (value, index) => {
    let ticketsCopy = [...ticketsPurchased];
    let updateTickets = [];
    ticketsCopy.forEach((element, i) => {
      if (i !== index) {
        updateTickets.push(element);
      } else {
        updateTickets.push({ name: element.name, ticketID: value });
      }

      if (ticketsCopy.length === i + 1) {
        setTicketsPurchased(updateTickets);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrMsg("");
    // on form submit we will check availability
    const ticketsPuchaseCopy = [...ticketsPurchased];
    let ticketAmounts = [];
    tickets.tickets.forEach((ticket, i) => {
      let amount = 0;
      ticketsPuchaseCopy.forEach((ticketCopy, j) => {
        if (ticketCopy.ticketID === ticket._id) {
          amount = amount + 1;
        }

        if (j + 1 === ticketsPuchaseCopy.length && amount > 0) {
          ticketAmounts.push({
            ticket: ticket._id,
            amount,
            total: amount * ticket.cost,
          });
        }
      });

      if (i + 1 === tickets.tickets.length) {
        // Check availability
        axios
          .post(`${APIURL}check`, ticketAmounts)
          .then((res) => {
            if (!res.data.data.allAvailable) {
              console.log(res.data.data);
              setErrMsg(
                "Sorry, there don't seem to be enough tickets for all your purchases."
              );
            } else {
              let finalTotal = 0;
              ticketAmounts.forEach((item, i) => {
                finalTotal = finalTotal + item.total;

                if (i + 1 === ticketAmounts.length) {
                  dispatch(purchaseTickets(ticketsPurchased));
                  dispatch(setFinalTotal(finalTotal));
                  navigate("/details");
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
        // if available we save tickets to redux state then move to the final form which will create pending payment
      }
    });
  };

  return (
    <div style={styles.container}>
      <div style={{ color: "white" }}>
        <h2 style={{ lineHeight: 0, marginBottom: 30 }}>Choose Your Tickets</h2>
        <div>
          <form onSubmit={handleSubmit}>
            {ticketsPurchased.map((element, i) => {
              return (
                <div
                  key={i}
                  style={{
                    marginTop: 20,
                    paddingTop: 10,
                    borderTop: i !== 0 ? `1px solid white` : null,
                  }}
                >
                  <div
                    style={
                      biggerThan600
                        ? {
                            display: "flex",
                            flexDirection: "row",
                          }
                        : {
                            display: "flex",
                            flexDirection: "column",
                          }
                    }
                  >
                    <div>
                      <div
                        style={{
                          color: colors.secondary,
                          marginBottom: 5,
                          marginTop: 5,
                          fontWeight: "bold",
                        }}
                      >
                        Name
                      </div>
                      <Input
                        type="text"
                        required
                        value={element.name}
                        placeholder="Ticket holder name"
                        onChange={(e) => handleNameChange(e.target.value, i)}
                        style={
                          biggerThan600
                            ? { paddingRight: 10, width: "98%" }
                            : { marginRight: 0, width: "100%" }
                        }
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          color: colors.secondary,
                          marginBottom: 5,
                          marginTop: 5,
                          fontWeight: "bold",
                        }}
                      >
                        Ticket
                      </div>
                      <select
                        onChange={(e) => {
                          handleTicketChange(e.target.value, i);
                        }}
                        style={styles.select}
                        required
                      >
                        <option value="" defaultValue="">
                          Select your ticket
                        </option>
                        {tickets.tickets.map((ticket, j) => {
                          return (
                            <option value={ticket._id} key={j}>
                              {ticket.viewName} {`(N$${ticket.cost})`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {i > 0 ? (
                    <button
                      style={{
                        color: "white",
                        backgroundColor: colors.secondary,
                        border: `1px solid ${colors.secondary}`,
                        borderRadius: 5,
                        paddingTop: 15,
                        paddingBottom: 15,
                        paddingLeft: 20,
                        paddingRight: 20,
                        cursor: "pointer",
                        width: "100%",
                        fontWeight: "bold",
                        marginTop: 10,
                      }}
                      onClick={() => handleRemoveTicket(i)}
                    >
                      Remove Ticket
                    </button>
                  ) : null}
                </div>
              );
            })}
            <div
              style={{
                marginTop: 20,
              }}
            >
              <div style={styles.btnOutline} onClick={handleAddTicket}>
                Add Another Ticket
              </div>
              <button
                type="submit"
                title="Confirm"
                style={{
                  color: colors.secondary,
                  backgroundColor: "white",
                  border: `1px solid white`,
                  borderRadius: 5,
                  paddingTop: 15,
                  paddingBottom: 15,
                  paddingLeft: 20,
                  paddingRight: 20,
                  cursor: "pointer",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                Continue
              </button>
            </div>
            <div
              style={{
                color: "red",
                marginTop: 10,
                maxWidth: "100%",
                fontWeight: "bold",
              }}
            >
              {errMsg}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: colors.text,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 10,
    marginTop: 64,
    marginBottom: 64,
    maxWidth: "80vw",
  },
  select: {
    border: "none",
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    outline: "none",
    fontSize: 16,
    color: colors.text,
    borderRadius: 5,
    width: "100%",
  },
  btnOutline: {
    backgroundColor: "transparent",
    color: "white",
    border: `1px solid white`,
    borderRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: "pointer",
    marginBottom: 10,
    textAlign: "center",
    fontWeight: "bold",
  },
  btn: {
    marginRight: 25,
  },
};

export default Form;
