import React from "react";
import { useParams } from "react-router-dom";
import colors from "../../config/colors";

const Result = ({ polling, downloadLinks }) => {
  const params = useParams();

  const ShowResult = () => {
    if (polling === "paid") {
      return (
        <div>
          <h2>Payment successful!</h2>
          <p>
            Please use the links below to download your ticket(s)
            <br />
            We have also sent them to you via email. If you did not receive
            them, please check your junk folder or contact support with your
            reference.
          </p>
          <p>Reference: {`${params.id}`}</p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {downloadLinks.map((link) => {
              return (
                <a
                  key={link}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginTop: 10, color: colors.secondary }}
                >
                  Download Link
                </a>
              );
            })}
          </div>
        </div>
      );
    } else if (polling === "loading") {
      return (
        <div>
          <h2>Loading...</h2>
          <p>
            Please give us a moment while we process your tickets. This could
            take up to a minute.
          </p>
          <p>DO NOT CLOSE THIS PAGE!</p>
        </div>
      );
    } else if (polling === "paid but uncreated") {
      return (
        <div>
          <h2>Payment Successful with an error</h2>
          <p>
            Your payment has been successful but we failed to produce your
            tickets. Please contact support, provide them the reference below
            and we will manually produce your tickets for you.
          </p>
          <p>Reference: {`${params.id}`}</p>
        </div>
      );
    } else if (polling === "error") {
      return (
        <div>
          <h2>There was an error checking your transaction status</h2>
        </div>
      );
    } else if (polling === "unpaid") {
      return (
        <div>
          <h2>Payment failed</h2>
          <p>You can retry by following the link below</p>
          <a href={`${window.location.origin}/payment/${params.id}`}>Retry</a>
        </div>
      );
    } else {
      return (
        <div>
          <h2>Loading...</h2>
        </div>
      );
    }
  };

  return (
    <div style={styles.container}>
      <div style={{ color: "white" }}>
        <ShowResult />
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: colors.text,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 10,
    marginTop: 64,
    marginBottom: 64,
    maxWidth: "80vw",
  },
};

export default Result;
