import React, { useEffect } from "react";

const ShowBtn = ({ thisPayment }) => {
  useEffect(() => {
    window.createButton(
      "3588",
      "BARHP Events",
      `${thisPayment.total * 100}`,
      `${window.location.origin}/postpayment/${thisPayment._id}`,
      `${thisPayment._id}`
    );
  }, []);
  return <div />;
};

export default ShowBtn;
