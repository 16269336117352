import React, { useState, useEffect } from "react";
import Navbar from "../../components/UI/Navbar";
import Backdrop from "../../components/UI/Backdrop";
import SideMenu from "../../components/UI/SideMenu";
import Button from "../../components/UI/Button";
import MainFooter from "../../components/UI/Footer";

import globalStyles from "../../config/globalStyles";
import { useSelector, useDispatch } from "react-redux";
import { MdHome, MdLabel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../actions/authActions";
import heroImg from "../../assets/hero.png";
import PaymentCard from "./PayemntCard";
import axios from "axios";
import { APIURL } from "../../config/vars";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [toggleSideMenu, setToggleSideMenu] = useState(false);
  const [payment, setPayemnt] = useState(null);
  const [polling, setPolling] = useState("loading");

  useEffect(() => {
    axios
      .get(`${APIURL}pendingpayments/${params.id}`)
      .then((res) => {
        setPayemnt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${APIURL}check/${params.id}`)
      .then((res) => {
        if (
          res.data.data.status === true ||
          res.data.data.status === "success"
        ) {
          setPolling("paid");
        } else {
          setPolling("unpaid");
        }
      })
      .catch((err) => {
        setPolling("error");
      });
  }, []);

  const handleSideMenu = () => {
    setToggleSideMenu(!toggleSideMenu);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate.push(`/login`);
  };

  const auth = useSelector((state) => state.auth);

  const Footer = () => {
    return (
      <Button
        style={styles.footerBtn}
        title="Logout"
        handlePress={handleLogout}
      />
    );
  };

  const bodyLinks = [
    {
      title: "Home",
      to: "/home",
      icon: <MdHome style={globalStyles.icon} />,
    },
  ];

  const authBodyLinks = [
    {
      title: "Tickets",
      to: "/tickets",
      icon: <MdLabel style={globalStyles.icon} />,
    },
  ];

  const NoID = () => {
    return <div>No Payment ID</div>;
  };

  return (
    <div>
      <div>
        <Navbar onClick={handleSideMenu} />
        {toggleSideMenu ? <Backdrop onClick={handleSideMenu} /> : null}
        {!auth.auth?.token ? (
          <SideMenu isOpen={toggleSideMenu} bodyLinks={bodyLinks} />
        ) : null}
        {auth.auth?.token ? (
          <SideMenu
            isOpen={toggleSideMenu}
            bodyLinks={authBodyLinks}
            footer={<Footer />}
          />
        ) : null}
        <div style={styles.container}>
          {!params.id ? (
            <NoID />
          ) : (
            <PaymentCard payment={payment} polling={polling} />
          )}
        </div>
        <MainFooter />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100vw",
    backgroundImage: `url(${heroImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    minHeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footerBtn: {
    boxShadow: "none",
  },
  content: {
    marginTop: 56,
  },
};

export default Payment;
