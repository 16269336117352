import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser } from "./actions/authActions";

import NotFound from "./views/notFound/NotFound";
// import PrivateRoute from "./views/auth/PrivateRoute";
import Home from "./views/home/Home";
import CustomerDetailsHome from "./views/home/CustomerDetailsHome";
import Payment from "./views/payment/Payment";
import PostPayment from "./views/postPayment/PostPayment";
import Auth from "./views/auth/Auth";
import RedirectHome from "./views/routing/RedirectHome";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        <React.Fragment>
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<CustomerDetailsHome />} path="/details" />
            <Route path="/home" element={<RedirectHome />} />
            <Route path="/payment/:id" element={<Payment />} />
            <Route path="/postpayment/:id" element={<PostPayment />} />
            <Route path="/login" element={<Auth />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </React.Fragment>
      </Router>
    </div>
  );
}

export default App;
