import React, { useState } from "react";
import colors from "../../config/colors";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import axios from "axios";
import { APIURL } from "../../config/vars";
import { Link, useNavigate } from "react-router-dom";
import TC from "../../assets/tc.pdf";
import { CountryDropdown } from "react-country-region-selector";
import globalStyles from "../../config/globalStyles";

const genderOptions = ["Male", "Female", "Other"];

const CustomerDetailsForm = ({ tickets }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [country, setCountry] = useState("");
  const [age, setAge] = useState("");
  const [selectedGender, setSelectedGender] = useState("");

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  if (!tickets?.ticketsToPurchase.length) {
    return (
      <div style={styles.container}>
        <div style={{ color: colors.secondary }}>
          <h2 style={{ lineHeight: 0 }}>You haven't chosen any tickets</h2>
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <Link style={{ color: "white" }} to="/">
              Choose your tickets
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const getTicketName = (id) => {
    const ticketName = tickets.tickets.find((element) => element._id === id);
    return ticketName.viewName;
  };

  const getTicketEvent = (id) => {
    const thisTicket = tickets.tickets.find((element) => element._id === id);
    return thisTicket.event;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedGender) return setErrMsg("Please select a gender");
    if (!country) return setErrMsg("Please select a country");
    setErrMsg("");
    const pendingPayment = {
      total: tickets.finalTotal,
      customerContactNumber: number,
      customerEmail: email,
      customerName: name,
      customerLocation: country,
      customerAge: age,
      customerGender: selectedGender,
    };

    let purchases = [];

    tickets.ticketsToPurchase.forEach((ticket, i) => {
      purchases.push({
        customerName: ticket.name,
        ticket: ticket.ticketID,
        event: getTicketEvent(ticket.ticketID),
      });

      if (i + 1 === tickets.ticketsToPurchase.length) {
        let ticketAmounts = [];
        tickets.tickets.forEach((ticket, j) => {
          let amount = 0;
          purchases.forEach((purchase, k) => {
            if (purchase.ticket === ticket._id) {
              amount = amount + 1;
            }

            if (k + 1 === purchases.length && amount > 0) {
              ticketAmounts.push({
                ticket: ticket._id,
                amount,
              });
            }
          });

          if (j + 1 === tickets.tickets.length) {
            const body = {
              purchases,
              pendingPayment,
              ticketsToCheck: ticketAmounts,
            };
            axios
              .post(`${APIURL}pendingpayments`, body)
              .then((res) => {
                if (!res.data.success) {
                  setErrMsg(
                    "There aren't enough tickets available for your purchase."
                  );
                } else {
                  navigate(`/payment/${res.data.data._id}`);
                }
              })
              .catch((err) => {
                console.log(err);
                setErrMsg(
                  "There was an issue with confirming your purchase. Please try again."
                );
              });
          }
        });
      }
    });
  };

  return (
    <div style={styles.container}>
      <div>
        <div style={{ display: "flex", flexDirection: "column-reverse" }}>
          <div style={{ margin: 10 }}>
            <h2 style={{ lineHeight: 0 }}>Buyer details</h2>
            <div>
              <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    type="text"
                    required
                    value={name}
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                    style={{ marginTop: 10 }}
                  />
                  <Input
                    type="email"
                    required
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ marginTop: 10, marginBottom: 10 }}
                  />
                  <Input
                    type="text"
                    required
                    value={number}
                    placeholder="Phone number"
                    onChange={(e) => setNumber(e.target.value)}
                    style={{ marginBottom: 10 }}
                  />
                  <Input
                    type="number"
                    required
                    min="18"
                    max="100"
                    value={age}
                    placeholder="Age"
                    onChange={(e) => setAge(e.target.value)}
                  />
                  <div style={{ marginTop: 10 }}>
                    <CountryDropdown
                      value={country}
                      onChange={(val) => setCountry(val)}
                      classes="country-dropdown"
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <select
                      id="gender"
                      value={selectedGender}
                      onChange={handleGenderChange}
                      style={styles.dropdown}
                    >
                      <option value="">Select a gender</option>
                      {genderOptions.map((gender) => (
                        <option key={gender} value={gender}>
                          {gender}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 10,
                      marginBottom: 20,
                      color: "white",
                    }}
                  >
                    <p>
                      I have read and accepted the{" "}
                      <a
                        download="terms-and-conditions.pdf"
                        href={`${TC}`}
                        style={{ color: "red" }}
                      >
                        terms and conditions
                      </a>
                    </p>
                    <input required type="checkbox"></input>
                  </div>
                  <button
                    style={{
                      color: "white",
                      backgroundColor: colors.secondary,
                      border: `1px solid ${colors.secondary}`,
                      borderRadius: 5,
                      paddingTop: 15,
                      paddingBottom: 15,
                      paddingLeft: 20,
                      paddingRight: 20,
                      cursor: "pointer",
                      width: "100%",
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                    type="submit"
                  >
                    Continue to payment
                  </button>
                  <div
                    style={{ color: "red", marginTop: 10, maxWidth: "100%" }}
                  >
                    {errMsg}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div style={{ margin: 10 }}>
            <h2 style={{ lineHeight: 0 }}>Purchase details</h2>
            <div style={{ paddingTop: 1 }}>
              {tickets.ticketsToPurchase.map((ticket, i) => {
                return (
                  <p key={i}>
                    {ticket.name} - {getTicketName(ticket.ticketID)}
                  </p>
                );
              })}
            </div>
            <div>
              <h4 style={{ lineHeight: 0 }}>Total:</h4>
              <p style={{ lineHeight: 0 }}>N${tickets.finalTotal}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: colors.text,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 10,
    marginTop: 104,
    marginBottom: 64,
    maxWidth: "80vw",
    color: "white",
  },
  select: {
    border: "none",
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    outline: "none",
    fontSize: 16,
    color: colors.text,
    boxSizing: "border-box",
    borderRadius: 5,
    maxWidth: 200,
  },
  btn: {
    marginRight: 25,
  },
  dropdown: {
    width: "100%",
    border: "none",
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: globalStyles.borderRadius,
  },
};

export default CustomerDetailsForm;
