import axios from "axios";
import { returnErrors } from "./errorActions";

import { GET_TICKETS, TICKETS_TO_PURCHASE, SET_FINAL_TOTAL } from "./types";

import { APIURL } from "../config/vars";

export const getTickets = () => (dispatch) => {
  axios
    .get(`${APIURL}tickets?isActive=true`)
    .then((res) => {
      dispatch({
        type: GET_TICKETS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const purchaseTickets = (tickets) => (dispatch) => {
  dispatch({
    type: TICKETS_TO_PURCHASE,
    payload: tickets,
  });
};

export const setFinalTotal = (total) => (dispatch) => {
  dispatch({
    type: SET_FINAL_TOTAL,
    payload: total,
  });
};
